@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poltawski+Nowy:ital,wght@0,400;0,500;1,400&display=swap");

.about-head img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}
.about-body {
  margin-top: 3rem;
}
.about-title {
  font: normal bold 30px/1 "Poltawski Nowy";
  text-align: center;

  margin-bottom: 20px;
}
.about-content {
  font: normal 18px/35px "Bona Nova";
}
.about-body-top {
  text-align: center;
  padding: 20px 0;
}
/* Vision Section */
.vision-section {
  display: flex;
  margin: 6rem 0;
  gap: 3rem;
}
.vision-left img {
  width: 600px;
  height: 350px;
  border-radius: 15px;
}
.vision-title {
  font: normal bold 30px/1 "Poltawski Nowy";
  margin-bottom: 1rem;
}

/* Infrastructure Section */
.infra-main {
  padding: 2rem 0;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  position: relative;
  margin: 4rem 0;
}

.infra-main::before {
  content: "";
  display: block;
  background-image: url("https://img.freepik.com/premium-photo/innovative-architecture-civil-engineering-plan_31965-4432.jpg?w=1380");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  animation: zoom 10s infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.infra-title {
  font: normal bold 30px/1 "Poltawski Nowy";
  margin-bottom: 3rem;

  text-align: center;
}
.infra-content {
  width: 700px;
  font: normal bold 19px/35px "Bona Nova";
  padding: 20px;
  margin-left: 5rem;
  opacity: 0;
  transform: translateY(-100%);
  animation: slide 7s forwards infinite;
}
@keyframes slide {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* Media Querry */

@media (max-width: 1060px) {
  .vision-section {
    flex-wrap: wrap;
    margin: 10px;
    gap: 1rem;
  }
  .about-head img {
    height: auto;
  }
  .about-title {
    font: normal bold 25px/1 "Bona Nova";
  }
  .about-content {
    font: normal 15px/27px "Bona Nova";
  }
  .vision-title {
    font: normal bold 25px/1 "Bona Nova";
  }
  .about-body-top {
    padding: 0;
  }
  .vision-left img {
    width: 100%;
    height: auto;
  }
  .infra-content {
    width: 100%;
    margin: 10px;
  }
  .infra-main::before {
    animation: none;
  }
  .infra-title {
    margin-top: 5rem;
    margin-bottom: 0;
    font: normal bold 25px/1 "Bona Nova";
  }
}
