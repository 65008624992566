@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poltawski+Nowy:ital,wght@0,400;0,500;1,400&display=swap");
.slide-img {
  height: 71vh;
  object-fit: cover;
}
.home-head {
  margin-bottom: 3rem;
}
.home-top-intro {
  padding: 0 25px;
  margin: 0 10rem;
  text-align: center;
  margin-bottom: 3rem;
}
.home-top-intro h3 {
  font: normal bold 30px/1 "Poltawski Nowy";
  margin-bottom: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.home-top-intro p {
  font: normal 18px/35px "Bona Nova";
}
/* Country Carousel */
.country-main {
  margin-bottom: 5rem;
}
.country-title {
  font: normal bold 30px/1 "Poltawski Nowy";
  text-align: center;
  padding: 20px 0;
  margin-bottom: 2rem;
}
.country-flex {
  margin: 0 6rem;
  background: linear-gradient(to right, #0575e6, #021b79);

  padding: 2rem;
}
.country-multi-carousel {
  display: flex;
  justify-content: space-evenly;
  padding: 2rem 0;
  background: linear-gradient(to right, #0575e6, #021b79);
  opacity: 0.9;
  margin: 0 3rem;
}
.country-card img {
  width: 170px;
  height: 100px;
  background-color: #e7e7e7;
  padding: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* Certification Flex */
.cert-main {
  /* margin: 5rem 0; */
  margin-bottom: 5rem;
}
.cert-flex img {
  width: 300px;
  height: 160px;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e7e7e7;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.cert-flex {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  gap: 5rem;
  /* background-color: #e7e7e7; */
  margin: 0 3rem;
}

/* Media Querry */
@media (max-width: 1060px) {
  .home-top-intro {
    margin: 10px;
    padding: 0;
    text-align: justify;
  }
  .home-top-intro p {
    font: normal 16px/25px "Bona Nova";
  }
  .slide-img {
    height: auto;
  }
  .country-title {
    font: normal bold 25px/1 "Bona Nova";
    margin: 1rem 0;
  }
  .country-multi-carousel {
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  .cert-flex {
    flex-wrap: wrap;
    margin: 10px;
    padding: 15px;
    gap: 2rem;
  }
  .cert-flex img {
    width: 100%;
  }
  .cert-main {
    margin: 2rem 0;
  }
  .product-list-flex {
    flex-wrap: wrap;
    margin: 10px;
    gap: 11px;
  }
  .product-list-flex img {
    width: 100%;
  }
}
