@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poltawski+Nowy:ital,wght@0,400;0,500;1,400&display=swap");

.contact-flex {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center;
  margin: 4rem 0;
}

.contact-title {
  font: normal bold 25px/1 "Poltawski Nowy";
  margin: 1rem 0;
  text-align: center;
}
/* Contact Left */
.contact-left {
  background-color: #eeeeee;
  padding: 30px 100px;
}
.contact-input input {
  padding: 14px 10px;
  width: 390px;
  border: none;
  outline: none;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.contact-message input {
  padding: 50px 10px;
  width: 390px;
  border: none;
  outline: none;
  margin-bottom: 35px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.contact-btn button {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  background: linear-gradient(to right, #0575e6, #021b79);
  color: white;
  font: normal 18px/1 "Bona Nova";
}
::placeholder {
  font: normal 15px/1 "Bona Nova";
}
.contact-form {
  margin-top: 3rem;
}
/* Contact Right */
.contact-right {
  background-color: #eeeeee;
  padding: 30px 40px;
  width: 550px;
}
.contact-sub-title {
  font: normal bold 20px/1 "Bona Nova";
  margin-bottom: 15px;
}
.contact-right-div {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: white;
  margin-bottom: 15px;
  gap: 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: 0.4s all ease-in-out;
}
.contact-right-div:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.contact-icon {
  font-size: 40px;
  border-radius: 50px;
  padding: 7px;
  background: linear-gradient(to right, #0575e6, #021b79);
  color: white;
  border: 1px solid #0575e6;
}
.contact-right-content {
  font: normal 17px/1 "Bona Nova";
  margin: 10px 0;
}

/* Media Querry */
@media (max-width: 1060px) {
  .contact-flex {
    flex-wrap: wrap;
    gap: 3rem;
    margin: 10px;
  }
  .contact-left {
    width: 100%;
    padding: 15px;
  }
  .contact-input input {
    width: 100%;
  }
  .contact-message input {
    width: 100%;
  }
  .contact-right {
    width: 100%;
    padding: 15px;
  }
  .contact-right-div {
    flex-wrap: wrap;
  }
}
