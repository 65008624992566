@import url("https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&display=swap");

.product-sub-head {
  text-align: center;
  margin: 3rem 0;
}
.product-sub-head h3 {
  font: normal bold 30px/1 "Bona Nova";
  margin-bottom: 3rem;
}
.product-sub-head {
  width: 100%;
}
.product-sub-flex img {
  width: 380px;
  height: 350px;
  object-fit: cover;
  padding: 5px;
  border-radius: 6px;
  background: linear-gradient(to right, #7090b1, #0575e6);
}
.product-sub-flex {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 3rem 0;
}

/* Media Querry */
@media (max-width: 1060px) {
  .product-sub-flex {
    flex-wrap: wrap;
    margin: 10px;
  }
  .product-sub-flex img {
    width: 100%;
    height: 350px;
  }
}
